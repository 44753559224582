import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { EverywhereErrorCodes } from "../../config/EverywhereErrorCodes";
import { EverywhereSuccessCodes } from "../../config/EverywhereSuccessCodes";
import { myOrdersSchema } from "./MyOrdersNormalization";
import MyOrdersRepository from "./MyOrdersRepository";

export interface MyOrder {
  id: number;
  bookingDate: Date;
  numberOfPeople: number;
  status: number;
  purchasedBy: number;
  smartPlanId?: number;
  smartPlanDateId?: number;
  masterClassId?: number;
  wellnessPsychoId?: number;
  wellnessNutritionalId?: number;
  wellnessBodyId?: number;
  tenantId: number;
  profilePhoto: string;
}

export interface CheckoutItem {
  name?: string;
  price?: string;
  description?: string;
  image?: string;
  successURL: string;
  cancelURL: string;
}

export interface CheckoutSession {
  id: string;
  url: string;
}

//#endRegion Type

//#region API

export const getAllOrdersAsync = createAsyncThunk(
  "myOrder/getAllOrders",
  async (data: { queryString?: string }) => {
    const myOrdersRepository = new MyOrdersRepository();
    const response = await myOrdersRepository.getAllOrders(data?.queryString);
    const allOrders = _.get(response, Config.MY_ORDER_RESPONSE);
    const normalizedData = normalize(allOrders, myOrdersSchema);
    return normalizedData.entities;
  }
);

export const getMyOrdersAsync = createAsyncThunk(
  "myOrder/getMyOrders",
  async (data: { queryString?: string }) => {
    const myOrdersRepository = new MyOrdersRepository();
    const response = await myOrdersRepository.getMyOrders(data?.queryString);
    const myOrders = _.get(response, Config.MY_ORDER_RESPONSE);
    return myOrders;
  }
);

export const getAssociatedOrdersAsync = createAsyncThunk(
  "myOrder/getAssociatedOrders",
  async (data: { queryString?: string }) => {
    const myOrdersRepository = new MyOrdersRepository();
    const response = await myOrdersRepository.getUserAssociatedOrders(
      data?.queryString
    );
    const myOrders = _.get(response, Config.MY_ORDER_RESPONSE);
    const normalizedData = normalize(myOrders, myOrdersSchema);
    return normalizedData.entities;
  }
);

export const createOrderAsync = createAsyncThunk(
  "myOrder/createOrder",

  async (data: { myOrder: MyOrder }) => {
    const myOrdersRepository = new MyOrdersRepository();

    const response = await myOrdersRepository.createOrder(data.myOrder);

    return _.get(response, Config.CREATE_ORDER_RESPONSE);
  }
);

export const createCheckoutSessionAsync = createAsyncThunk(
  "myOrder/createCheckoutSession",
  async (data: { checkoutItem: CheckoutItem; bookingId: number }) => {
    const myOrdersRepository = new MyOrdersRepository();

    const response = await myOrdersRepository.createCheckoutSession(
      data.checkoutItem,
      data.bookingId
    );

    return _.get(response, Config.CREATE_ORDER_RESPONSE);
  }
);

export const createCheckoutSessionForCreditsAsync = createAsyncThunk(
  "credits/createCheckoutSession",
  async (data: { checkoutItem: CheckoutItem }) => {
    const myOrdersRepository = new MyOrdersRepository();

    const response = await myOrdersRepository.createCheckoutSessionForCredits(
      data.checkoutItem
    );

    return _.get(response, Config.CREATE_ORDER_RESPONSE);
  }
);

export const createWellnessOrderAsync = createAsyncThunk(
  "myOrder/createWellnessOrder",

  async (data: { myOrder: MyOrder }) => {
    const myOrdersRepository = new MyOrdersRepository();
    const response = await myOrdersRepository.createWellnessOrder(data.myOrder);
    return _.get(response, Config.CREATE_ORDER_RESPONSE);
  }
);

//#endRegion API

//#region Slice

const myOrdersAdapter = createEntityAdapter<MyOrder>({
  selectId: (myOrder) => myOrder.id,
});

export const myOrdersSlice = createSlice({
  name: "myOrders",
  initialState: myOrdersAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    elementOrdered: 0,
  }),
  reducers: {
    myOrdersEmptyState: (state) => {
      myOrdersAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
      state.elementOrdered = 0;
    },
    myOrdersElementOrderedEmptyState: (state) => {
      state.elementOrdered = 0;
      state.reasonCode = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllOrdersAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myOrdersAdapter.setAll(state, action.payload.myOrders ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getAllOrdersAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAllOrdersAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getMyOrdersAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myOrdersAdapter.setAll(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getMyOrdersAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getMyOrdersAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        getAssociatedOrdersAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          myOrdersAdapter.setAll(state, action.payload.myOrders ?? []);
          state.status = "idle";
          state.reasonCode = EverywhereSuccessCodes.GET;
        }
      )
      .addCase(getAssociatedOrdersAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAssociatedOrdersAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        createOrderAsync.fulfilled,
        (state: any, action: PayloadAction<MyOrder>) => {
          myOrdersAdapter.upsertOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = "ordered";
          state.elementOrdered = action.payload.id;
        }
      )
      .addCase(createOrderAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createOrderAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      })
      .addCase(
        createCheckoutSessionAsync.fulfilled,
        (state: any, action: PayloadAction<MyOrder>) => {
          myOrdersAdapter.upsertOne(state, action.payload);
          state.status = "idle";
          state.reasonCode = "ordered";
          state.elementOrdered = action.payload.id;
        }
      )
      .addCase(createCheckoutSessionAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(createCheckoutSessionAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = EverywhereErrorCodes.INTERNAL_SERVER_ERROR;
      });
  },
});

//#endRegion Slice

//#region Status

export const myOrdersSelector = myOrdersAdapter.getSelectors<RootState>(
  (state) => state.myOrders
);

export const { myOrdersEmptyState } = myOrdersSlice.actions;
export const { myOrdersElementOrderedEmptyState } = myOrdersSlice.actions;
export const selectMyOrdersSliceStatus = (state: any) => state.myOrders.status;
export const selectMyOrdersSliceReasonCode = (state: any) =>
  state.myOrders.reasonCode;
export const selectMyOrdersSliceElementOrdered = (state: any) =>
  state.myOrders.elementOrdered;

//#endRegion Status

export default myOrdersSlice.reducer;

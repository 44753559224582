import { t } from "i18next";
import { OfficeCoworkingFilterBar } from "./OfficeCoworkingFilterBar";
import { useEffect, useRef, useState } from "react";
import {
  OfficeCoworkingActivityTail,
  getOfficeCoworkingActivityTailAsync,
  officeCoworkingActivityTailSelector,
} from "./OfficeCoworkingActivityTailSlice";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { TwentyIcTable } from "../../ui/components/icons/TwentyIcTable";
import "./OfficeCoworking.css";
import OfficeCoworkingActivityTailComponent from "./OfficeCoworkingActivityTail";
import React from "react";
import OfficeCoworkingFilterModal from "./OfficeCoworkingFilterModal";
import { Footer } from "../../screens/Footer/Footer";
import { QueryParams } from "../Smartplan/SmartPlan";
import { getQueryString } from "../../utils/utils";
import MyOrders from "../../ui/components/MyOrders/MyOrders";
import { MyOrder, myOrdersSelector } from "../myorders/MyOrdersSlice";

export const OfficeCoworking = () => {
  const [switchSelected, setSwitchSelected] = useState(0);
  const [modalOpened, setModalOpened] = useState(false);

  const [searchKey, setSearchKey] = useState("");
  const [filters, setFilters] = useState(new Map());
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [stringifiedQueryParams, setStringifiedQueryParams] = useState("");
  const [refreshed, setRefreshed] = useState(false);

  const elementsLimit = 3;

  const activityTails: OfficeCoworkingActivityTail[] = useAppSelector(
    officeCoworkingActivityTailSelector.selectAll
  );

  const myOrders: MyOrder[] = useAppSelector(myOrdersSelector.selectAll);

  const refreshActivityTail = (
    params: Map<string, string[] | string>
  ): void => {
    const newQueryParams = { ...queryParams }; // Clona gli attuali query parameters

    if (params.size > 0) {
      params.forEach((value, key) => {
        // Gestisci i valori nulli o vuoti in modo appropriato
        if (
          value !== null &&
          value !== undefined &&
          (Array.isArray(value) ? value.length > 0 : value !== "")
        ) {
          newQueryParams[key] = value;
        } else {
          delete newQueryParams[key];
        }
      });
    } else {
      // Se la mappa dei parametri è vuota, azzeriamo i parametri esistenti
      Object.keys(newQueryParams).forEach((key) => {
        delete newQueryParams[key];
      });
    }
    store.dispatch(
      getOfficeCoworkingActivityTailAsync({
        queryString: getQueryString(newQueryParams),
      })
    );
    return;
  };

  // function fillMyOrdersResults(): JSX.Element[] {
  //   var elements = [];
  //   if (!!myOrders) {
  //     for (let i = 0; i < myOrders.length; i++) {
  //       let temp = activityTails.find(
  //         (el) => el.id === myOrders[i].smartPlanId
  //       );
  //       let temp2 = smartDatePlan.find(
  //         (el) => el.id === myOrders[i].smartPlanDateId
  //       );
  //       elements.push(
  //         <React.Fragment>
  //           <MyOrders
  //             id={myOrders[i].id}
  //             date={myOrders[i].bookingDate}
  //             price={temp2?.price.toString()}
  //             deposit={temp2?.deposit.toString()}
  //             image={temp?.image}
  //             status={myOrders[i].status.toString()}
  //             titleOne={temp?.city}
  //             titleTwo={temp?.region}
  //             personsNumber={myOrders[i].numberOfPeople}
  //             smartPlanId={myOrders[i].smartPlanId}
  //             type={"SmartPlan"}
  //           />
  //         </React.Fragment>
  //       );
  //     }
  //     return elements;
  //   } else {
  //     return [];
  //   }
  // }
  function fillMyOrdersPendingResults(): JSX.Element[] {
    var elements = [] as any;
    if (false) {
      for (let i = 0; i < myOrders.length; i++) {
        let temp = activityTails.find(
          (el) => el.id === myOrders[i].smartPlanId
        );

        // elements.push(
        //   <React.Fragment>
        //     <MyOrders
        //       id={myOrders[i].id}
        //       date={myOrders[i].bookingDate}
        //       price={temp2?.price.toString()}
        //       deposit={temp2?.deposit.toString()}
        //       image={temp?.image}
        //       status={myOrders[i].status.toString()}
        //       titleOne={temp?.city}
        //       titleTwo={temp?.region}
        //       personsNumber={myOrders[i].numberOfPeople}
        //       smartPlanId={myOrders[i].smartPlanId}
        //       type={"SmartPlan"}
        //     />
        //   </React.Fragment>
        // );
      }
      return elements;
    } else {
      elements.push(
        <React.Fragment>
          <MyOrders
            id={1}
            officeCoworkingId={1}
            date={new Date()}
            price={"65656"}
            deposit={"65656"}
            isPending={true}
            pendingDate={new Date()}
            pendingOrderDate={new Date()}
            image={
              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCoCNH_52-CLDZ2JFp1fFyS6hLgUlSOUzUtceo1e-R9w&s"
            }
            status={"1"}
            pendingStatus={"Preso in carico"}
            titleOne={"Gravina"}
            titleTwo={"MIno Cicchella"}
            personsNumber={3}
            smartPlanId={3}
            type={"SmartPlan"}
            profilePhoto={""}
          />
        </React.Fragment>
      );

      return elements;
    }
  }

  useEffect(() => {
    refreshActivityTail(filters);
  }, [refreshed]);

  useEffect(() => {
    store.dispatch(getOfficeCoworkingActivityTailAsync({}));
  }, []);

  function fillEmptyActivityTailsResults(counter: number): JSX.Element[] {
    var elements = [];
    for (var i = 0; i < elementsLimit - counter; i++) {
      elements.push(
        <div className="smartplan-activitytail-transparent-container">
          <OfficeCoworkingActivityTailComponent
            id={undefined}
            img={undefined}
            city={undefined}
            region={undefined}
            reactionButton={() => {}}
            title={undefined}
            arrow={false}
          />
        </div>
      );
    }
    return elements;
  }

  function fillActivityTailsResults(): JSX.Element[] {
    var elements = [];
    if (!!activityTails) {
      var filtered = activityTails.filter(
        (x: OfficeCoworkingActivityTail) =>
          x.name
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey?.toLocaleLowerCase()) ||
          x.city
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey?.toLocaleLowerCase()) ||
          x.region
            ?.toString()
            .toLocaleLowerCase()
            .includes(searchKey?.toLocaleLowerCase())
      );
      for (let i = 0; i < Math.ceil(filtered.length / 3); i++) {
        var children = [];
        var columnCounter = 0;
        for (
          let j = i * elementsLimit;
          j < filtered.length && columnCounter < elementsLimit;
          j++
        ) {
          children.push(
            <React.Fragment>
              <OfficeCoworkingActivityTailComponent
                id={filtered[j].id}
                title={filtered[j].name}
                img={filtered[j].image}
                city={filtered[j].city}
                region={filtered[j].region}
                arrow={true}
                reactionButton={() => {}}
              />
            </React.Fragment>
          );
          columnCounter++;
        }
        children.push(fillEmptyActivityTailsResults(columnCounter));
        elements.push(
          <div className="smartplan-activitytail-container-row">{children}</div>
        );
      }
      return elements;
    }
    return [];
  }

  return (
    <>
      <div className="office-coworking-content">
        <div className="frame-16">
          <div className="frame-17">
            <div className="smartplan-topbar">
              <div className="smartplan-topbar-title-iconplace">
                <TwentyIcTable
                  className="smartplan-topbar-title-iconplace-icon"
                  color="#36b39e"
                />
              </div>
              <div className="smartplan-topbar-title">Coworking</div>
            </div>
          </div>
        </div>
        <OfficeCoworkingFilterBar
          setSwitchSelected={(e) => {
            setSwitchSelected(e);
          }}
          setModalOpened={(value) => {
            setModalOpened(value);
          }}
          filters={filters}
          searched={(e) => setSearchKey(e)}
          setFilters={(e) => {
            setFilters(e);
            setRefreshed(!refreshed);
          }}
          switchStatus={switchSelected}
        />
        {switchSelected === 0 && (
          <div className="smartplan-activitytail-container">
            {fillActivityTailsResults()}
          </div>
        )}
        {/*
        {switchSelected === 1 && (
          <div className="smartplan-myorder-container">
            {fillMyOrdersPendingResults()}
          </div>
        )} */}
        {/* {switchSelected === 2 && (
          <div className="smartplan-myorder-container">
            {myOrders.length > 0 ? (
              fillMyOrdersResults()
            ) : (
              <div className="smartplan-myorderempty-container">
                <div className="smartplan-myorderempty-element">
                  <TwentyIcEverywhere2
                    dimension={40}
                    color="#222729"
                    className={"smartplan-myorderempty-icon"}
                  ></TwentyIcEverywhere2>
                  <div className="smartplan-myorderempty-firsttext">
                    {t("myOrders.smartOrderEmptyFirstText")}
                  </div>
                  <div className="smartplan-myorderempty-secondtext">
                    {t("myOrders.smartOrderEmptySecondText")}
                  </div>
                </div>
              </div>
            )}
          </div>
        )} */}

        {modalOpened && (
          <OfficeCoworkingFilterModal
            setParam={(e) => {
              setFilters(e);
              setRefreshed(!refreshed);
            }}
            modalChange={(value) => {
              setModalOpened(value);
            }}
            isModalOpened={modalOpened}
            queryParams={filters}
          ></OfficeCoworkingFilterModal>
        )}
      </div>
      <Footer />
    </>
  );
};

import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import { PreviewProvider } from "./Context/PrewiewContext";
import { HubSpotWidget } from "./HSChat";
import NewDAccountDetails from "./feature/Account/NewDAccountDetails";
import PaymentSuccess from "./feature/Payments/PaymentSuccess";
import { PasswordChanged } from "./feature/RecoverPassword/PasswordChanged";
import { RecoverPassword } from "./feature/RecoverPassword/RecoverPassword";
import { Resetpassword } from "./feature/RecoverPassword/ResetPassword";
import { SendEmail } from "./feature/RecoverPassword/SendEmail";
import SidebarContainer from "./layout/SidebarContainer";
import LoginPage from "./screens/Login/LoginPage";
import { NewProviderAccount } from "./feature/Account/NewProviderAccount/NewProviderAccount";
import { PrivacyAndPolicy } from "./feature/Account/PrivacyAndPolicy/PrivacyAndPolicy";
import { ConfirmMeeting } from "./feature/Meetings/ConfirmMeeting";

interface HubSpotCredential {
  email: string;
  token: string;
}

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true" ? true : false
  );

  const [auth, setAuth] = useState(
    localStorage.getItem("jwtToken") !== null ||
      localStorage.getItem("refreshToken") !== null
  );

  const [hubSpotCredential, setHubSpotCredential] = useState({
    email: localStorage.getItem("email"),
    token: localStorage.getItem("chatToken"),
  } as HubSpotCredential);

  const loginDone = () => {
    const currentEmail = localStorage.getItem("email");
    const currentChatToken = localStorage.getItem("chatToken");

    if (currentChatToken && currentEmail) {
      setHubSpotCredential({
        email: currentEmail,
        token: currentChatToken,
      } as HubSpotCredential);
    }
    setAuth(true);
  };

  const logoutDone = () => {
    setHubSpotCredential({ email: "", token: "" } as HubSpotCredential);
    setAuth(false);
  };

  const isLogged = () => {
    setAuth(true);
    setIsLoggedIn(true);
  };

  return (
    <PreviewProvider>
      <div className="App-containter">
        <div className="toast-wrapper">
          <ToastContainer theme="colored" />
        </div>
        <BrowserRouter>
          <>
            <Routes>
              {auth ? (
                <>
                  {!isLoggedIn ? (
                    <>
                      <Route
                        path="/*"
                        element={
                          <LoginPage
                            loginDone={loginDone}
                            isLogged={isLogged}
                          />
                        }
                      />
                      <Route
                        path="/privacyPolicy"
                        element={<PrivacyAndPolicy />}
                      />
                      <Route
                        path="/addProvider"
                        element={
                          <NewProviderAccount
                            isLogged={isLogged}
                            logoutDone={logoutDone}
                          />
                        }
                      />
                      <Route
                        path="/confirmMeeting"
                        element={<ConfirmMeeting />}
                      />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/*"
                        element={<SidebarContainer logoutDone={logoutDone} />}
                      />
                      <Route
                        path={"/smartplan/:id/success/:id"}
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/masterclass/:id/success/:id"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/wellness/body/:id/success/:id"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/wellness/nutritional/:id/success/:id"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/wellness/psycho/:id/success/:id"
                        element={<PaymentSuccess />}
                      />
                      <Route
                        path="/confirmMeeting"
                        element={<ConfirmMeeting />}
                      />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Route
                    path="/*"
                    element={
                      <LoginPage loginDone={loginDone} isLogged={isLogged} />
                    }
                  />
                  <Route
                    path="/addUser"
                    element={<NewDAccountDetails loginDone={loginDone} />}
                  />
                  <Route
                    path="/recover-password"
                    element={<RecoverPassword />}
                  />
                  <Route path="/send-email" element={<SendEmail />} />
                  <Route path="/resetPassword" element={<Resetpassword />} />
                  <Route
                    path="/password-changed"
                    element={<PasswordChanged />}
                  />
                  <Route path="/confirmMeeting" element={<ConfirmMeeting />} />
                </>
              )}
            </Routes>
          </>
        </BrowserRouter>
        <HubSpotWidget
          email={hubSpotCredential.email}
          token={hubSpotCredential.token}
          accountId={process.env.REACT_APP_HS_ACCOUNT_ID}
        />
      </div>
    </PreviewProvider>
  );
}

export default App;

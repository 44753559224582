import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import QuestionsAndAnswers from "../../../ui/components/QuestionsAndAnswersComponent/QuestionsAndAnswers";
import {
  IncludedService,
  getWellnessPsychoIncludedServices,
  includedServiceSelector,
} from "../../IncludedServices/IncludedServiceSlice";
import "./WellnessPsychoDetails.css";

import {
  Faq,
  faqSelector,
  getWellnessPsychoFaqsAsync,
} from "../../Faq/FaqSlice";

import _ from "lodash";
import { useMatch, useNavigate } from "react-router-dom";
import { store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";

import { ImageConfig } from "../../../config/ImageConfig";
import { WellnessSummaryComponent } from "../../../ui/components/WellnessSummaryComponent";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import {
  PublicAccount,
  getPublicAccountByTenantIdAndUserIdAsync,
} from "../../Account/PublicAccount/PublicAccountSlice";
import {
  Wallet,
  getWalletAsync,
  walletSelector,
} from "../../Wallet/WalletSlice";
import {
  MyOrder,
  createWellnessOrderAsync,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import WellnessBookingModal from "../WellnessBookingModal/WellnessBookingModal";
import {
  WellnessPsycho,
  getWellnessPsychoAsync,
  wellnessPsychoSelector,
} from "../WellnessSlice/WellnessPsychoSlice";

export const WellnessPsychoDetails = () => {
  const [id, setId] = useState<number>(-1);

  let firstLoad = false;

  const wellnessPsychoDetailsRoute = useMatch("Wellness/Psycho/:id/details");

  const bookingStatus = useAppSelector(selectMyOrdersSliceStatus);

  useEffect(() => {
    if (!firstLoad) {
      if (wellnessPsychoDetailsRoute !== null) {
        if (wellnessPsychoDetailsRoute.params.id) {
          setId(parseInt(wellnessPsychoDetailsRoute.params.id));
          store.dispatch(
            getWellnessPsychoAsync(
              parseInt(wellnessPsychoDetailsRoute.params.id)
            )
          );
          store.dispatch(
            getWellnessPsychoFaqsAsync({
              wellnessPsychoId: parseInt(wellnessPsychoDetailsRoute.params.id),
            })
          );
          store.dispatch(
            getWellnessPsychoIncludedServices({
              wellnessId: parseInt(wellnessPsychoDetailsRoute.params.id),
            })
          );
          firstLoad = true;
          return;
        }
      }
    }
  }, [wellnessPsychoDetailsRoute]);

  const [bookNowModalId, setBookNowModalId] = useState<number>(-1);

  let wellNessPsycho: WellnessPsycho = useAppSelector(
    (state) =>
      wellnessPsychoSelector.selectById(state, id) ?? ({} as WellnessPsycho)
  );

  let includedService: IncludedService[] = useAppSelector(
    includedServiceSelector.selectAll
  );

  let faq: Faq[] = useAppSelector(faqSelector.selectAll);

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  const date = new Date();
  const hoursItalian = date.setHours(date.getHours() + 2);
  const userInfo = localStorage.getItem("customerId");
  const userInfoId = userInfo ? parseInt(userInfo, 10) : 0;
  const tenant = localStorage.getItem("tenantId");
  const tenantId = tenant ? parseInt(tenant, 10) : 0;
  const order: MyOrder = {
    numberOfPeople: 1,
    id: 0,
    tenantId: tenantId,
    bookingDate: new Date(hoursItalian),
    status: 0,
    purchasedBy: userInfoId,
    wellnessPsychoId: wellNessPsycho.id,
    profilePhoto: "",
  };

  const [publicAccount, setPublicAccount] = useState<PublicAccount>();
  const [isActionPerforming, setIsActionPerforming] = useState(false);

  const [walletId, setWalletId] = useState(-1);

  const wallet: Wallet = useAppSelector(
    (state) => walletSelector.selectById(state, walletId) ?? ({} as Wallet)
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!_.isEmpty(wellNessPsycho)) {
        const accountDetails = await store.dispatch(
          getPublicAccountByTenantIdAndUserIdAsync({
            tenantId: wellNessPsycho?.tenant?.id,
            userId: wellNessPsycho?.userInfo?.id,
          })
        );
        setPublicAccount(accountDetails.payload);
      }
      store
        .dispatch(getWalletAsync())
        .then((response) => setWalletId(response?.payload?.id));
    };
    fetchData();
  }, [wellNessPsycho]);

  const navigate = useNavigate();

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={wellNessPsycho.name}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            t("wellness.wellness") +
            " > " +
            t("wellness.psycho.ourPsycho") +
            " > " +
            wellNessPsycho.name
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate("/Wellness/Psycho")}
        />
      </HeaderMenu>
      <div className="wellnesspsychodetail-container">
        <div className="wellness-details-image-container-provider">
          <img
            className="rectangle-8"
            alt=""
            src={ImageConfig.IMAGE_DEFAULT_SERVICE}
          />
          {publicAccount?.personalPhotography && (
            <img
              className="wellness-image2-user-provider-psycho"
              alt=""
              src={publicAccount?.personalPhotography}
            />
          )}
        </div>
        <div className="overlap-2">
          <div className="wellnesspsychodetail-bady-page">
            <div className="text-wrapper-21-service">
              {t("smartPlanDetail.smartPlanServiceComponent.service")}
            </div>
            <div className="service-container">
              <div>
                <div className="service-title">
                  {t("wellness.services.mode")}
                </div>
                <div className="service-subtitle">
                  {wellNessPsycho.remotelyOrPresenceEnum === "REMOTEANDPRESENCE"
                    ? t("stringBuilder.remoteAndPresence")
                    : wellNessPsycho.remotelyOrPresenceEnum === "REMOTE"
                    ? t("stringBuilder.remote")
                    : t("stringBuilder.presence")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.duration")}
                </div>
                <div className="service-subtitle">
                  {wellNessPsycho.durationMeeting}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.typology")}
                </div>
                <div className="service-subtitle">
                  {!wellNessPsycho.isIndividual
                    ? t("stringBuilder.singleSession")
                    : t("stringBuilder.multiSession")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.sessions")}
                </div>
                <div className="service-subtitle">
                  {wellNessPsycho.numberMeeting > 1 &&
                    wellNessPsycho.numberMeeting +
                      " " +
                      t("stringBuilder.sessions")}
                  {wellNessPsycho.numberMeeting === 1 &&
                    wellNessPsycho.numberMeeting +
                      " " +
                      t("stringBuilder.session")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.assistance")}
                </div>
                <div className="service-subtitle">
                  {t("wellness.services.included")}
                </div>
              </div>
              {wellNessPsycho.city && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.location")}
                  </div>
                  <div className="service-subtitle">{wellNessPsycho.city}</div>
                </div>
              )}
            </div>
            <div className="text-wrapper-21-description">
              {t("smartPlanDetail.description.description")}
            </div>
            <div className="description-wellness-psycho-details">
              <Descriptions
                initialText={
                  wellNessPsycho.description ? wellNessPsycho.description : ""
                }
                maxLength={300}
              />
            </div>
            <div className="frame-36">
              <div className="frame-22">
                <div className="text-wrapper-37">
                  {t("smartPlanDetail.questionsAndAnswers.questionsAndAnswers")}
                </div>
                <div className="contattaci-su-wrapper">
                  <p className="contattaci-su">
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.contactUs")}
                    </span>
                    <span className="text-wrapper-38">WhatsApp</span>
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.callUs")}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="container-answer">
              <div className="line-bookNow-initial" />
              {faq.map((el) => (
                <div>
                  <QuestionsAndAnswers title={el.question} desc={el.answer} />
                </div>
              ))}
            </div>
          </div>

          <div className="container-summary">
            <div className="summary-wrapper">
              <WellnessSummaryComponent
                type="PSYCHO"
                email={wellNessPsycho?.userInfo?.email}
                voucherPrice={wellNessPsycho.price}
                telephone={wellNessPsycho?.userInfo?.telephone}
                onClick={() => {
                  setBookNowModalId(wellNessPsycho?.id);
                }}
              />
              <AssistanceBoxComponent
                assistance={t(
                  "smartPlanDetail.componentAssistantBox.assistance"
                )}
                whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
                date={t("smartPlanDetail.componentAssistantBox.date")}
                whatsAppNumber={3773604135}
                whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                  .replace("firstName*", firstName ?? "")
                  .replace("lastName*", lastName ?? "")
                  .replace("companyName*", businessName ?? "")}
                disabled={undefined}
              />
            </div>
          </div>
          {!_.isEmpty(wellNessPsycho) && bookNowModalId > 0 && (
            <WellnessBookingModal
              onClose={() => {
                navigate("/Wellness/Psycho/" + wellNessPsycho.id + "/details");
                setBookNowModalId(-1);
              }}
              onClick={() => {
                setIsActionPerforming(true);
                store
                  .dispatch(createWellnessOrderAsync({ myOrder: order }))
                  .then((result) => {
                    if (result.meta.requestStatus === "fulfilled") {
                      setIsActionPerforming(false);
                      navigate(
                        "/wellness/psycho/" +
                          id +
                          "/success/" +
                          result.payload.id
                      );
                    }
                  })
                  .catch(() => {
                    setIsActionPerforming(false);
                    ToastNotification({
                      status: "error",
                      description: "",
                      toastId: "Booking not created",
                      title: t("myOrders.orderFailed"),
                    });
                  });
              }}
              totalCredit={wallet && wallet.currentCredits}
              price={wellNessPsycho?.price}
              isActionPerforming={isActionPerforming}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

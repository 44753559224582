import { t } from "i18next";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";

import { Spin } from "antd";
import PublicAccountActivityTail from "../../../ui/components/ActivityTail/PublicAccountActivityTail";
import { WellnessNutritionalFilterBar } from "../../../ui/components/FilterBar/WellnessNutritionalFilterBar";
import { WellnessNutritionalFilterModal } from "../../../ui/components/Modals/WellnessNutritionalFilterModal";
import MyOrders from "../../../ui/components/MyOrders/MyOrders";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcZen } from "../../../ui/components/icons/TwentyIcZen";
import { getQueryString } from "../../../utils/utils";
import {
  PublicAccountView,
  getPublicAccountViewAsync,
  publicAccountViewSelector,
} from "../../Account/PublicAccount/PublicAccountViewSlice";
import {
  MyOrder,
  getAssociatedOrdersAsync,
  getMyOrdersAsync,
  myOrdersSelector,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import {
  WellnessNutritionalView,
  WellnessNutritionalViewSelector,
} from "../WellnessSlice/WellnessNutritionalViewSlice";
import "./WellnessNutritional.css";

export interface QueryParams {
  [paramName: string]: any;
}

export const WellnessNutritional = () => {
  const navigate = useNavigate();
  const [elementSelected, setElementSelected] = useState(0);

  const myOrders: MyOrder[] = useAppSelector(myOrdersSelector.selectAll);
  const myOrdersStatus: any = useAppSelector(selectMyOrdersSliceStatus);

  const [isFiltered, setIsFiltered] = useState(false);
  const [modalOpened, setModalOpened] = useState(false);
  const [refreshed, setRefreshed] = useState(false);

  const [modalParams, setQueryParams] = useState<QueryParams>({});
  const [stringifiedQueryParams, setStringifiedQueryParams] = useState("");
  const [prevStringifiedQueryParams, setPrevStringifiedQueryParams] =
    useState("");
  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;
  const [filters, setFilters] = useState(new Map());

  const [searchKey, setSearchKey] = useState("");
  const elementsLimit = 3;

  const activityTails: WellnessNutritionalView[] = useAppSelector(
    WellnessNutritionalViewSelector.selectAll
  );

  const activityTailsPublicAccount: PublicAccountView[] = useAppSelector(
    publicAccountViewSelector.selectAll
  );

  useEffect(() => {
    if (searchKey) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [searchKey]);

  const refreshActivityTail = (
    params: Map<string, string[] | string>
  ): void => {
    const newQueryParams = { ...queryParams }; // Clona gli attuali query parameters

    if (params.size > 0) {
      params.forEach((value, key) => {
        // Gestisci i valori nulli o vuoti in modo appropriato
        if (
          value !== null &&
          value !== undefined &&
          (Array.isArray(value) ? value.length > 0 : value !== "")
        ) {
          newQueryParams[key] = value;
        } else {
          delete newQueryParams[key];
        }
      });
    } else {
      // Se la mappa dei parametri è vuota, azzeriamo i parametri esistenti
      Object.keys(newQueryParams).forEach((key) => {
        delete newQueryParams[key];
      });
    }
    setQueryParams(newQueryParams);
    setStringifiedQueryParams(getQueryString(newQueryParams));

    return;
  };

  function fillMyOrdersResults(): JSX.Element[] {
    var elements = [];
    if (!!myOrders) {
      for (let i = 0; i < myOrders.length; i++) {
        let temp = activityTails.find(
          (el) => el.id === myOrders[i].wellnessNutritionalId
        );
        elements.push(
          <React.Fragment>
            <MyOrders
              id={myOrders[i].id}
              date={myOrders[i].bookingDate}
              price={temp?.price?.toString()}
              wellnessNutritionalId={myOrders[i].wellnessNutritionalId}
              image={temp?.image}
              status={myOrders[i].status.toString()}
              titleOne={temp?.name}
              titleTwo={""}
              personsNumber={myOrders[i].numberOfPeople}
              type={"Wellness"}
              deposit={temp?.price?.toString()}
              profilePhoto={myOrders[i].profilePhoto}
            />
          </React.Fragment>
        );
      }
      return elements;
    } else {
      return [];
    }
  }

  useEffect(() => {
    if (stringifiedQueryParams !== prevStringifiedQueryParams) {
      store.dispatch(
        getPublicAccountViewAsync({
          queryString: stringifiedQueryParams,
          providerType: "NUTRITIONIST",
        })
      );
    }
    setPrevStringifiedQueryParams(stringifiedQueryParams);
  }, [stringifiedQueryParams]);

  useEffect(() => {
    setIsFiltered(filters.size > 0);
    refreshActivityTail(filters);
  }, [filters, filters.size]);

  useEffect(() => {
    store.dispatch(
      getPublicAccountViewAsync({
        providerType: "NUTRITIONIST",
      })
    );
  }, []);

  useEffect(() => {
    if (elementSelected === 1) {
      if (myOrders.length === 0) {
        store.dispatch(
          getMyOrdersAsync({ queryString: "?type=wellnessNutritional" })
        );
      }
    }
  }, [elementSelected]);

  useEffect(() => {
    if (elementSelected === 2) {
      if (myOrders.length === 0) {
        store.dispatch(
          getAssociatedOrdersAsync({ queryString: "?type=wellnessNutritional" })
        );
      }
    }
  }, [elementSelected]);

  function fillEmptyActivityTailsResults(counter: number): JSX.Element[] {
    var elements = [];
    for (var i = 0; i < elementsLimit - counter; i++) {
      elements.push(
        <div className="wellnessnutritional-activitytail-transparent-container">
          <PublicAccountActivityTail
            id={undefined}
            firstName={""}
            lastName={""}
            personalPhotography={""}
            typologies={[]}
            navigation={() => {}}
          />
        </div>
      );
    }
    return elements;
  }

  function fillActivityTailsResults(): JSX.Element[] {
    var elements = [];
    var filtered: any[] = [];
    var filterFromFilterBar = filters?.get("typology") as string[];

    filtered = activityTailsPublicAccount.filter(
      (x: PublicAccountView) =>
        x?.firstName
          ?.toLocaleLowerCase()
          .includes(searchKey?.toLocaleLowerCase()) ||
        x?.lastName
          ?.toLocaleLowerCase()
          .includes(searchKey?.toLocaleLowerCase())
    );
    if (!_.isEmpty(filters)) {
      filtered = filtered.filter((el) =>
        el?.typologies.some((typology: any) =>
          filterFromFilterBar?.some((filter) =>
            typology?.name.toLowerCase().includes(filter?.toLowerCase())
          )
        )
      );
    }

    if (filtered.length > 0) {
      for (let i = 0; i < Math.ceil(filtered.length / 3); i++) {
        var children = [];
        var columnCounter = 0;
        for (
          let j = i * elementsLimit;
          j < filtered.length && columnCounter < elementsLimit;
          j++
        ) {
          children.push(
            <React.Fragment>
              <PublicAccountActivityTail
                id={filtered[j].id}
                firstName={filtered[j].firstName}
                lastName={filtered[j].lastName}
                personalPhotography={filtered[j].personalPhotography}
                typologies={filtered[j].typologies}
                navigation={(e) => {
                  navigate("/account/" + e + "/details/public");
                }}
              />
            </React.Fragment>
          );
          columnCounter++;
        }
        children.push(fillEmptyActivityTailsResults(columnCounter));
        elements.push(
          <div className="wellnessnutritional-activitytail-container-row">
            {children}
          </div>
        );
      }
    } else {
      elements.push(
        <React.Fragment>
          <div className="smartplan-myorderempty-container">
            <div className="smartplan-myorderempty-element">
              <TwentyIcZen
                size="40"
                color="#222729"
                className={"smartplan-myorderempty-icon"}
              />
              <div className="smartplan-myorderempty-firsttext">
                {t("wellness.noServicesAvabileFirstText")}
              </div>
              <div className="smartplan-myorderempty-secondtext">
                {t("wellness.noServicesAvabileSecondText")}
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return elements;
  }

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={t("wellness.nutritional.networkNutritional")}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            t("wellness.wellness") +
            " > " +
            t("wellness.nutritional.networkNutritional")
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate("/Wellness")}
        />
      </HeaderMenu>
      <div className="wellnessnutritional-content">
        <WellnessNutritionalFilterBar
          setSwitchSelected={(value) => {
            setElementSelected(value);
          }}
          setModalOpened={(e) => {
            elementSelected === 0 && setModalOpened(e);
          }}
          filters={filters}
          searched={(e) => {
            setSearchKey(e);
          }}
          setFilters={(e) => {
            setFilters(e);
            setRefreshed(!refreshed);
          }}
          switchStatus={elementSelected}
        />
        {elementSelected === 0 && isFiltered && <div className="divider" />}
        {elementSelected === 0 && (
          <>
            <div className="wellnessnutritional-activitytail-results-bar">
              <div className="wellnessnutritional-activitytail-results-title">
                <div className="wellnessnutritional-activitytail-results-title-one">
                  {t("smartPlan.common.results")}
                </div>
                <div className="wellnessnutritional-activitytail-results-title-two">
                  (
                  {
                    activityTailsPublicAccount.filter(
                      (x: PublicAccountView) =>
                        x?.typologies?.some((typology: any) =>
                          filters
                            ?.get("typology")
                            ?.some((filter: any) =>
                              typology?.name
                                .toLowerCase()
                                .includes(filter?.toLowerCase())
                            )
                        ) ||
                        x?.firstName
                          ?.toLocaleLowerCase()
                          .includes(searchKey?.toLocaleLowerCase()) ||
                        x?.lastName
                          ?.toLocaleLowerCase()
                          .includes(searchKey?.toLocaleLowerCase())
                    ).length
                  }
                  )
                </div>
              </div>
            </div>
            {fillActivityTailsResults()}
          </>
        )}
        {elementSelected === 1 && (
          <>
            <div className="smartplan-myorder-container">
              {myOrders.length > 0 ? (
                fillMyOrdersResults()
              ) : myOrdersStatus === "loading" ? (
                <>
                  <Spin size="large" />
                </>
              ) : (
                <div className="smartplan-myorderempty-container">
                  <div className="smartplan-myorderempty-element">
                    <TwentyIcZen
                      size="40"
                      color="#222729"
                      className={"smartplan-myorderempty-icon"}
                    />
                    <div className="smartplan-myorderempty-firsttext">
                      {t("wellness.noServicesAvabileFirstText")}
                    </div>
                    <div className="smartplan-myorderempty-secondtext">
                      {t("wellness.noServicesBuyed")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        {elementSelected === 2 && (
          <>
            <div className="smartplan-myorder-container">
              {myOrders.length > 0 ? (
                fillMyOrdersResults()
              ) : myOrdersStatus === "loading" ? (
                <>
                  <Spin size="large" />
                </>
              ) : (
                <div className="smartplan-myorderempty-container">
                  <div className="smartplan-myorderempty-element">
                    <TwentyIcZen
                      size="40"
                      color="#222729"
                      className={"smartplan-myorderempty-icon"}
                    />
                    <div className="smartplan-myorderempty-firsttext">
                      {t("wellness.noServicesAvabileFirstText")}
                    </div>
                    <div className="smartplan-myorderempty-secondtext">
                      {t("wellness.noServicesBuyed")}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {modalOpened && elementSelected === 0 && (
        <WellnessNutritionalFilterModal
          modalChange={(value: any) => {
            setModalOpened(value);
          }}
          setParam={(e: any) => {
            setFilters(e);
          }}
          isModalOpened={modalOpened}
          queryParams={modalParams}
        />
      )}
      <Footer />
    </>
  );
};

import { t } from "i18next";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { AssistanceBoxComponent } from "../../../ui/components/AssistanceBoxComponent";
import { Descriptions } from "../../../ui/components/Descriptions";
import QuestionsAndAnswers from "../../../ui/components/QuestionsAndAnswersComponent/QuestionsAndAnswers";
import { getWellnessBodyIncludedServices } from "../../IncludedServices/IncludedServiceSlice";
import "./WellnessBodyDetails.css";

import { Faq, faqSelector, getWellnessBodyFaqsAsync } from "../../Faq/FaqSlice";
import { wellnessFaqs } from "../wellness_faqs";

import _ from "lodash";
import { useMatch, useNavigate } from "react-router-dom";
import { store } from "../../../app/store";
import HeaderMenu from "../../../layout/HeaderMenu";
import { Footer } from "../../../screens/Footer/Footer";
import { TopBar } from "../../../ui/components/TopBar/TopBar";
import { TwentyIcArrowLeft } from "../../../ui/components/icons/TwentyIcArrowLeft";
import { TwentyIcArrowLineLeft } from "../../../ui/components/icons/TwentyIcArrowLineLeft";
import { TwentyIcArrowRight } from "../../../ui/components/icons/TwentyIcArrowRight";
import {
  Gallery,
  gallerySelector,
  getWellnessBodyGalleyAsync,
} from "../../Gallery/GallerySlice";

import { Image } from "antd";
import { ImageConfig } from "../../../config/ImageConfig";
import { WellnessSummaryComponent } from "../../../ui/components/WellnessSummaryComponent";
import { ToastNotification } from "../../../ui/components/utils/ToastNotification";
import { getPublicAccountByTenantIdAndUserIdAsync } from "../../Account/PublicAccount/PublicAccountSlice";
import {
  Wallet,
  getWalletAsync,
  walletSelector,
} from "../../Wallet/WalletSlice";
import {
  MyOrder,
  createWellnessOrderAsync,
  selectMyOrdersSliceStatus,
} from "../../myorders/MyOrdersSlice";
import WellnessBookingModal from "../WellnessBookingModal/WellnessBookingModal";
import {
  WellnessBody,
  getWellnessBodyAsync,
  wellnessBodySelector,
} from "../WellnessSlice/WellnessBodySlice";

export const WellnessBodyDetails = () => {
  const [id, setId] = useState<number>(-1);

  const wellnessBodyDetailsRoute = useMatch("Wellness/Body/:id/details");

  const [walletId, setWalletId] = useState(-1);

  const wallet: Wallet = useAppSelector(
    (state) => walletSelector.selectById(state, walletId) ?? ({} as Wallet)
  );

  const bookingStatus = useAppSelector(selectMyOrdersSliceStatus);

  useEffect(() => {
    if (wellnessBodyDetailsRoute !== null) {
      if (wellnessBodyDetailsRoute?.params?.id) {
        setId(parseInt(wellnessBodyDetailsRoute?.params?.id));
        store.dispatch(
          getWellnessBodyAsync(parseInt(wellnessBodyDetailsRoute?.params?.id))
        );
        store.dispatch(
          getWellnessBodyFaqsAsync({
            wellnessBodyId: parseInt(wellnessBodyDetailsRoute?.params?.id),
          })
        );
        store.dispatch(
          getWellnessBodyIncludedServices({
            wellnessId: parseInt(wellnessBodyDetailsRoute?.params?.id),
          })
        );
        store.dispatch(
          getWellnessBodyGalleyAsync({
            wellnessId: parseInt(wellnessBodyDetailsRoute?.params?.id),
          })
        );
        store
          .dispatch(getWalletAsync())
          .then((response) => setWalletId(response?.payload?.id));
        return;
      }
    }
  }, [wellnessBodyDetailsRoute]);

  const [isGalleryOpen, setGalleryOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isActionPerforming, setIsActionPerforming] = useState(false);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const openGallery = (index: number) => {
    setSelectedImageIndex(index);
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  let wellNessBody: WellnessBody = useAppSelector(
    (state) =>
      wellnessBodySelector.selectById(state, id) ?? ({} as WellnessBody)
  );

  let faq = wellnessFaqs;

  let gallery: Gallery[] = useAppSelector(gallerySelector.selectAll);

  const imageList = gallery.map((el) => el.image);

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const businessName = localStorage.getItem("businessName");

  const date = new Date();
  const hoursItalian = date.setHours(date.getHours() + 2);
  const userInfo = localStorage.getItem("customerId");
  const userInfoId = userInfo ? parseInt(userInfo, 10) : 0;
  const tenant = localStorage.getItem("tenantId");
  const tenantId = tenant ? parseInt(tenant, 10) : 0;
  const order: MyOrder = {
    numberOfPeople: 1,
    id: 0,
    tenantId: tenantId,
    bookingDate: new Date(hoursItalian),
    status: 0,
    purchasedBy: userInfoId,
    wellnessBodyId: wellNessBody.id,
    profilePhoto: "",
  };

  useEffect(() => {
    const handleKeyPress = (e: any) => {
      if (isGalleryOpen) {
        if (e.key === "ArrowLeft") {
          const newIndex =
            currentImageIndex === 0
              ? imageList.length - 1
              : currentImageIndex - 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        } else if (e.key === "ArrowRight") {
          const newIndex =
            currentImageIndex === imageList.length - 1
              ? 0
              : currentImageIndex + 1;
          setSelectedImageIndex(newIndex);
          setCurrentImageIndex(newIndex);
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isGalleryOpen, imageList, currentImageIndex]);

  const [publicAccount, setPublicAccount] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      if (!_.isEmpty(wellNessBody)) {
        const accountDetails = await store.dispatch(
          getPublicAccountByTenantIdAndUserIdAsync({
            tenantId: wellNessBody?.tenant?.id,
            userId: wellNessBody?.userInfo?.id,
          })
        );
        setPublicAccount(accountDetails?.payload);
      }
    };
    fetchData();
  }, [wellNessBody]);

  const navigate = useNavigate();

  const [bookNowModalId, setBookNowModalId] = useState<number>(-1);

  return (
    <>
      <HeaderMenu>
        <TopBar
          title={wellNessBody.name}
          hasEdit={false}
          hasDate={false}
          breadcrumbText={
            t("wellness.wellness") +
            " > " +
            t("wellness.home.bodyService") +
            " > " +
            wellNessBody.name
          }
          icon={<TwentyIcArrowLineLeft className="icon-instance-node-3" />}
          onClick={() => navigate("/Wellness/Body")}
        />
      </HeaderMenu>
      <div className="wellnessbodydetail-container">
        <img
          className="rectangle-8"
          alt=""
          src={
            wellNessBody.image
              ? wellNessBody.image
              : ImageConfig.IMAGE_DEFAULT_SERVICE
          }
        />
        <div className="overlap-2">
          <div className="wellnessbodydetail-bady-page">
            <div className="text-wrapper-21-service">
              {t("smartPlanDetail.smartPlanServiceComponent.service")}
            </div>
            <div className="service-container">
              <div>
                <div className="service-title">
                  {t("wellness.services.mode")}
                </div>
                <div className="service-subtitle">
                  {wellNessBody.remotelyOrPresenceEnum === "REMOTEANDPRESENCE"
                    ? t("stringBuilder.remoteAndPresence")
                    : wellNessBody.remotelyOrPresenceEnum === "REMOTE"
                    ? t("stringBuilder.remote")
                    : t("stringBuilder.presence")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.duration")}
                </div>
                <div className="service-subtitle">
                  {wellNessBody.durationMeeting}
                </div>
              </div>
              {wellNessBody.city && (
                <div>
                  <div className="service-title">
                    {t("wellness.services.location")}
                  </div>
                  <div className="service-subtitle">{wellNessBody.city}</div>
                </div>
              )}
              <div>
                <div className="service-title">
                  {t("wellness.services.typology")}
                </div>
                <div className="service-subtitle">
                  {!wellNessBody.isIndividual
                    ? t("stringBuilder.singleSession")
                    : t("stringBuilder.multiSession")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.sessions")}
                </div>
                <div className="service-subtitle">
                  {wellNessBody.numberMeeting > 1 &&
                    wellNessBody.numberMeeting +
                      " " +
                      t("stringBuilder.sessions")}
                  {wellNessBody.numberMeeting === 1 &&
                    wellNessBody.numberMeeting +
                      " " +
                      t("stringBuilder.session")}
                </div>
              </div>
              <div>
                <div className="service-title">
                  {t("wellness.services.assistance")}
                </div>
                <div className="service-subtitle">
                  {t("wellness.services.included")}
                </div>
              </div>
            </div>
            <div className="provider-text">Fornitore</div>
            <div
              className="onClick-publicAccount"
              onClick={() =>
                navigate("/account/" + publicAccount?.id + "/details/public")
              }
            >
              <div className="image-provider">
                <Image
                  src={publicAccount?.personalPhotography}
                  width={50}
                  height={50}
                ></Image>
              </div>
              <div className="credentials-provider">
                {publicAccount?.firstName + " " + publicAccount?.lastName}
              </div>
            </div>
            {gallery.length !== 0 && (
              <>
                <div className="title-gallery">
                  {t("smartPlanDetail.gallery")}
                </div>
                <div className="smart-plan">
                  {" "}
                  <div className="flex-container-gallery">
                    {gallery.map((item, index) => (
                      <div className="gallery-container" key={item.id}>
                        <img
                          className="gallery-image"
                          src={item.image}
                          aria-hidden
                          alt={`Image ${item.id}`}
                          onClick={() => openGallery(index)}
                        />
                      </div>
                    ))}
                  </div>
                  {isGalleryOpen && (
                    <div className="gallery-overlay">
                      <button className="close-button" onClick={closeGallery}>
                        x
                      </button>
                      <button
                        className="prev-button"
                        onClick={() =>
                          setSelectedImageIndex((prev) =>
                            prev === 0 ? imageList.length - 1 : prev - 1
                          )
                        }
                      >
                        <TwentyIcArrowLeft className="arrow-gallery" />
                      </button>
                      <button
                        className="next-button"
                        onClick={() =>
                          setSelectedImageIndex((prev) =>
                            prev === imageList.length - 1 ? 0 : prev + 1
                          )
                        }
                      >
                        <TwentyIcArrowRight
                          className="arrow-gallery"
                          onClick={undefined}
                        />
                      </button>
                      <div className="gallery">
                        <img
                          src={imageList[selectedImageIndex]}
                          alt="img"
                          className="gallery-image-large"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            <div className="text-wrapper-21-description">
              {t("smartPlanDetail.description.description")}
            </div>
            <div className="description-wellness-body-details">
              <Descriptions
                initialText={
                  wellNessBody.description ? wellNessBody.description : ""
                }
                maxLength={300}
              />
            </div>
            <div className="frame-36">
              <div className="frame-22">
                <div className="text-wrapper-37">
                  {t("smartPlanDetail.questionsAndAnswers.questionsAndAnswers")}
                </div>
                <div className="contattaci-su-wrapper">
                  <p className="contattaci-su">
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.contactUs")}
                    </span>
                    <span className="text-wrapper-38">WhatsApp</span>
                    <span className="text-wrapper-39">
                      {t("smartPlanDetail.questionsAndAnswers.callUs")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="container-answer">
              <div className="line-bookNow-initial" />
              {faq.map((el) => (
                <div>
                  <QuestionsAndAnswers title={el.question} desc={el.answer} />
                </div>
              ))}
            </div>
          </div>
          <div className="container-summary">
            <div className="summary-wrapper">
              <WellnessSummaryComponent
                type="BODY"
                email={wellNessBody?.userInfo?.email}
                voucherPrice={wellNessBody.price}
                telephone={wellNessBody?.userInfo?.telephone}
                onClick={() => {
                  setBookNowModalId(wellNessBody?.id);
                }}
              />
              <AssistanceBoxComponent
                assistance={t(
                  "smartPlanDetail.componentAssistantBox.assistance"
                )}
                whatsApp={t("smartPlanDetail.componentAssistantBox.whatsApp")}
                date={t("smartPlanDetail.componentAssistantBox.date")}
                whatsAppNumber={3773604135}
                whatsAppMessageText={"Ciao, sono firstName* lastName* dell'azienda companyName*, ho bisogno di assistenza."
                  .replace("firstName*", firstName ?? "")
                  .replace("lastName*", lastName ?? "")
                  .replace("companyName*", businessName ?? "")}
                disabled={undefined}
              />
            </div>
          </div>
          {!_.isEmpty(wellNessBody) && bookNowModalId > 0 && (
            <WellnessBookingModal
              onClose={() => {
                navigate("/Wellness/Body/" + wellNessBody.id + "/details");
                setBookNowModalId(-1);
              }}
              onClick={() => {
                setIsActionPerforming(true);
                store
                  .dispatch(createWellnessOrderAsync({ myOrder: order }))
                  .then((result) => {
                    if (result.meta.requestStatus === "fulfilled") {
                      setIsActionPerforming(false);
                      navigate(
                        "/wellness/body/" + id + "/success/" + result.payload.id
                      );
                    }
                  })
                  .catch(() => {
                    setIsActionPerforming(false);
                    ToastNotification({
                      status: "error",
                      description: "",
                      toastId: "Booking not created",
                      title: t("myOrders.orderFailed"),
                    });
                  });
              }}
              totalCredit={wallet && wallet.currentCredits}
              price={wellNessBody?.price}
              isActionPerforming={isActionPerforming}
            />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
